/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




jQuery(document).ready(function() {
  // Enable Home Slider
  var homeslider = jQuery("#pageHeader .blulab-slider ul").lightSlider({
    item: 1,
    autoWidth: false,
    slideMove: 1, // slidemove will be 1 if loop is true
    slideMargin: 0,

    addClass: '',
    mode: "fade",
    useCSS: true,
    cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
    easing: 'swing', //'for jquery animation',////

    speed: 600, //ms'
    auto: true,
    loop: true,
    slideEndAnimation: true,
    pause: 5000,

    keyPress: false,
    controls: false,
    prevHtml: '',
    nextHtml: '',

    rtl:false,
    adaptiveHeight:true,

    vertical:false,
    verticalHeight:500,
    vThumbWidth:100,

    thumbItem:10,
    pager: false,
    gallery: false,
    galleryMargin: 5,
    thumbMargin: 5,
    currentPagerPosition: 'middle',

    enableTouch:true,
    enableDrag:true,
    freeMove:true,
    swipeThreshold: 40,

    responsive : [],

    onBeforeStart: function (el) {},
    onSliderLoad: function (el) {},
    onBeforeSlide: function (el) {},
    onAfterSlide: function (el) {},
    onBeforeNextSlide: function (el) {},
    onBeforePrevSlide: function (el) {}
  });


  // Enable lightgallery
  jQuery("#lightgallery").lightGallery();
  jQuery(".videoBox>div").lightGallery();


  // Custom dropdown in network

    jQuery('.wrapper-dropdown').click( function() {
      jQuery('.wrapper-dropdown').not(this).find('.dropdown').slideUp(200);
      jQuery(this).find('.dropdown').slideToggle(200).parent();
    });

    jQuery('#continents a').click( function(e){
      e.preventDefault();
      jQuery('#continents a').removeClass('active');
      jQuery('#continent').html(jQuery(this).html());
      console.log('#countries li:not(.'+jQuery(this).attr('class')+')');

      jQuery('#countries li:not(.'+jQuery(this).attr('class')+')').hide();
      jQuery('#countries li.'+jQuery(this).attr('class')).show();
      jQuery(this).addClass('active');
    });


});

jQuery(window).load( function() {
  jQuery('.gridIsotope').isotope({
      // options
      itemSelector: '.gridIsotopeItem',
      layoutMode: 'masonry'
    });
  /*jQuery('.postGrid').isotope({
      // options
      itemSelector: '.gridBox.post',
      layoutMode: 'masonry'
    }); */
});

jQuery(function() {
    jQuery('.box').matchHeight();
    jQuery('.gridBox.attivita').matchHeight();
    jQuery('.videoBox').matchHeight();
    jQuery('.gridBox.post').matchHeight();
});

// Integrazione richiesta da hbb.bz per il Booking engine
jQuery(document).ready(function() {
  if(jQuery(window).width()>768){
    /* This is basic - uses default settings */
    jQuery(".prenota-el a").fancybox({
      padding : 0,
      margin : 0,
      fitToView    : true,
      width        : '99%',
      height        : '98%',
      autoSize    : false,
      closeClick    : false,
      openEffect    : 'none',
      autoCenter  : true,
      type : 'iframe'
    });
  }
});

